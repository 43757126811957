html{
    padding: 0;
    margin: 0;
}

body {
    font-family: 'Times New Roman', serif;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: baseline;
    border-bottom: solid 1px black;
}

h1 {
    font-weight: 400;
    color: black;
    text-decoration: none;
}

h2 {
    font-weight: normal;
}

footer {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding: 20px;
    border-top: solid 1px black;
    margin-top: auto;
}

p {
    margin: 0;
}

address {
    font-style: normal;
}

address a {
    color: cadetblue;
    text-decoration: none;
}

address p {
    font-weight: 600;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.top-nav a {
    color: black;
    padding: 5px;
    text-decoration: none;
    border: 1px solid black;
    margin: 0.25em;
}

.top-nav a:hover {
    color: white;
    background-color: black;
}

.header-wrapper {
    width: 1000px;
    display: flex;
    justify-content: space-between;
}

.header-wrapper a{
    text-decoration: none;
}

.content {
    grid-area: content;
}

.single-column {
    display: grid;
    grid-template-columns: auto 1000px auto;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
    ". content .";
    margin: 50px;
}

.text {
    width: clamp(45ch, 50%, 75ch);
    text-align: justify;
}

.top-nav {
    font-size: 1.25em;
    display: flex;
    align-items: center;
}

.text-wrapper {
    display: flex;
    flex-direction: column;
}

.left {
    align-self: flex-start;
}

.right {
    align-self: flex-end;
}

.right h2 {
    text-align: right;
}

.date {
    font-size: 1.1em;
    text-decoration: underline;
    color: grey;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fit, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.image-grid-item {
    height: 13em;
    width: 13em;
    text-decoration: none;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain;
    color: black;
    font-size: large;
    border: 1px solid black;
}

.image-grid-text {
    grid-column: span 2;
    padding: 20px;
    border: 1px solid black;
}

.image-grid-text p {
    margin-top: 20px;
}

.image-grid-container {
    width: min-content;
}

.text-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
    font-size: 1.5em;
    text-align: center;
    color: black;
}

.zitat {
    width: 100%;
    box-sizing: border-box;
    padding: 25px;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
}

.zitat q {
 font-size: 1.2em;
}

.zitat p {
    margin-top: 20px;
    font-size: 0.75em;
}

.thema-left-container {
    width: 350px;
    height: 600px;
    margin: 50px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
}

.gliederung div{
    margin-bottom: 3px;
}

.gliederung a{
    display: block;
    font-size: 0.9em;
    color: black;
    text-decoration: none;
    text-align: right;
}

.gliederung a:hover {
    text-decoration: underline;
}

.gliederung a.active {
    width: 100%;
    text-decoration: underline;
}

.thema-title {
    font-size: 1.7em;
    text-align: center;
}

.slideshow {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 25px auto 25px;
    grid-template-rows: 100%;
}

.slideshow-image {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
}

.slideshow-image-container {
    position: relative;
    width: 100%;
    height: 100%;
    grid-area: 1 / 2 / 1 / 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slideshow-image-text {
    position: absolute;
    bottom: 0;
    left: 0;
}

.slideshow-image-info-box{
    font-size: 0.8em;
    padding: 5px;
    background-color: white;
    width: 17em;
    border: 1px solid black;
    border-collapse: collapse;
}

.slideshow-image-info-box tr {
    border: 1px solid black;
}

.slideshow-image-info-box td{
    padding: 5px;
}

.slideshow-image-info-box td:first-child {
    text-align: left;
    vertical-align: top;
}

.slideshow-image-info-box td:last-child {
    text-align: right;
}

.slideshow-image-info-small{
    padding: 2px 10px 2px 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}

.slideshow-button-container {
    display: flex;
    align-items: center;
    height: fit-content;
    align-self: center;
}

.slideshow-button {
    box-sizing: border-box;
    line-height: 2em;
    font-size: 1.5em;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0;
    height: 2em;
    width: 100%;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
}

.slideshow-left {
    grid-area: 1 / 1 / 2 / 2;
    justify-content: flex-start;
    margin-right: 5px;
}

.slideshow-right {
    margin-left: 5px;
    grid-area: 1 / 3 / 2 / 4;
    justify-content: flex-end;
}

.slideshow-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.slideshow-preview-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    grid-area: 2 / 1 / 3 / 4;
}

.slideshow-preview-container::-webkit-scrollbar {
    display: none;
}

.slideshow-preview-btn {
    aspect-ratio: 1 / 1;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin: 5px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain;
}

.slideshow-fullscreen-container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    border: solid 1px black;
    margin: auto;
    padding: 20px;
    z-index: 999;
}

.slideshow-fullscreen-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    border: none;
    cursor: pointer;
    font-size: 2.5em;
    margin: 5px;
    width: 50px;
    height: 50px;
    font-weight: 200;
    z-index: 9999;
}

.slideshow-preview-btn:first-child {
    margin-left: 0;
}

.slideshow-preview-btn:last-child {
    margin-right: 0;
}

.image-overview {
    display: grid;
    width: 600px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fit, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.image-overview-img {
    aspect-ratio: 1 / 1;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin: 5px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain;
}

.image-grid-item:hover .text-overlay {
    height: 100%;
}

.center {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.biografie {
    width: 100%;
    border-collapse: collapse;
}

.biografie td {
    padding: 15px;
    vertical-align: top;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
}

.biografie td:first-child {
    width: 20%;
    font-size: 1.1em;
}

.biografie-dual-container {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.biografie-portrait {
    height: 300px;
    width: auto;
}
